import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, Provider } from 'react-redux'
import { Route, Switch, withRouter, matchPath } from 'react-router-dom'
 



import { loadUser } from '../actions/users-actions'
import { loadValidate, logout } from '../actions/authentication-actions'
import { loadEmbersonCompanies } from '../actions/embersonCompanies-actions'
import asyncComponent from "../components/AsyncComponent";
import PrivateRoute from '../components/PrivateRoute'

import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import Header from './Header' 

const Home = asyncComponent(() => import("../containers/Home"));
const Login = asyncComponent(() => import("../containers/Login"));
const ResetPassword = asyncComponent(() => import("../containers/ResetPassword"));
const MyAccount = asyncComponent(() => import("../containers/MyAccount"));
const Page404 = asyncComponent(() => import("../containers/Page404"));
const UpdatePassword = asyncComponent(() => import("../containers/UpdatePassword"));
const Proposals = asyncComponent(() => import("../containers/Proposals"));
const AddProposal = asyncComponent(() => import("../containers/AddProposal"));
const ViewProposal = asyncComponent(() => import("../containers/ViewProposal"));
const Users = asyncComponent(() => import("../containers/Users"));
const EditUser = asyncComponent(() => import("../containers/EditUser"));
const AddUser = asyncComponent(() => import("../containers/AddUser"));
const Companies = asyncComponent(() => import("../containers/Companies"));
const AddCompany = asyncComponent(() => import("../containers/AddCompany"));
const Settings = asyncComponent(() => import("../containers/Settings"));
const SettingsSuperadmin = asyncComponent(() => import("../containers/SettingsSuperadmin"));
const ViewClient = asyncComponent(() => import("../containers/ViewClient"));



class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,  
    loadUser: PropTypes.func.isRequired,
    loadValidate: PropTypes.func.isRequired,
    loadEmbersonCompanies: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props) 

    this.state = {
      viewAs: 0,
    }; 

    this.changeViewState = this.changeViewState.bind(this);
  }

  componentWillMount() { 
    this.props.loadEmbersonCompanies()
    const token = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;
    const authenticated = this.props.authentication.isAuthenticated;
    if (token && authenticated) { 
      this.props.loadValidate()
        .then((response) => { 
          this.props.loadUser('me').then((response) => { 
            this.setState({viewAs: response.response.companyId})
          })
        })
    } 
    this.props.history.listen(location => window.scrollTo(0, 0));
    if (token && authenticated) { 
    this.props.loadEmbersonCompanies().then((response) => { 
      console.log(response.response.entities.embersonCompanies)
        let embersonCompanies = response.response.entities.embersonCompanies[this.state.viewAs];
   
        if (embersonCompanies) {
          let previousCompany = this.props.embersonCompanies[this.props.user.companyId].companyName;
          document.body.classList.remove(previousCompany.replace(/\s/g, ""));
          document.body.classList.add(embersonCompanies.companyName.replace(/\s/g, ""));
        } 
   
       
    }) 
    }
  }
  
  componentDidMount() {
    this.props.loadUser('me').then((response) => { 
      if(!response.error) {
        this.setState({viewAs: response.response.companyId})
            this.props.loadEmbersonCompanies().then((response) => {  
            let embersonCompanies = response.response.entities.embersonCompanies[this.state.viewAs];
       
            if (embersonCompanies) {
              let previousCompany = this.props.embersonCompanies[this.props.user.companyId].companyName;
              document.body.classList.remove(previousCompany.replace(/\s/g, ""));
              document.body.classList.add(embersonCompanies.companyName.replace(/\s/g, ""));
            } 
       
           
        })
      }
    }) 

     


    
  }
  
  changeViewState(companyId) {
    this.setState({viewAs: companyId})
    document.body.className = 'g-sidenav-show bg-gray-100';
    
    const embersonCompanies = this.props.embersonCompanies[companyId];
    if (embersonCompanies) {
      let previousCompany = this.props.embersonCompanies[this.props.user.companyId].companyName;
      
      document.body.classList.add(embersonCompanies.companyName.replace(/\s/g, ""));

    } else {
      document.body.classList.add('superAdmin');

    } 
  }
 
 
  render() {
    const { store } = this.props
    const user = this.props.user;
    const userId = user.iD; 
    let embersonCompanies = this.props.embersonCompanies;

     
    

    let viewAs = this.state.viewAs;

    const titles = {
      '/': 'Dashboard',
      '/potentials': 'Potentials',
      '/users': 'Users',
      '/add-potential': 'Add Potential',
      '/potential/:proposalId': 'Potential',
      '/my-account': 'My Account',
      '/companies': 'Companies',
      '/add-company': 'Add Company',
      '/add-emberson-company': 'Add Company',
      '/settings': 'Settings',
    }

    let title = '';
    if (matchPath(this.props.location.pathname, { path: '/potential/:proposalId' })) {
      title = 'Potential';
    } else if (matchPath(this.props.location.pathname, { path: '/edit-potential/:proposalId' })) {
      title = 'Edit Potential';
    } else if (matchPath(this.props.location.pathname, { path: '/edit-user/:userId' })) {
      title = 'Edit User';
    } else if (matchPath(this.props.location.pathname, { path: '/edit-company/:clientId' })) {
      title = 'Edit Client';
    } else if (matchPath(this.props.location.pathname, { path: '/company/:clientId' })) {
      title = 'View Client';
    } else if (matchPath(this.props.location.pathname, { path: '/edit-emberson-company/:companyId' })) {
      title = 'Edit Company';
    } else {
      title = titles[this.props.location.pathname];
    }
       
 
    
    if (this.props.location.pathname === "/sign-in") {
      return (
        <Provider store={store}>
          <main role="main" className="login-page">
            <Switch>
              <Route exact path="/sign-in" component={Login} />
            </Switch>
          </main>
        </Provider>
      )
    } else if (this.props.location.pathname === "/reset-password/") {
      return (
        <Provider store={store}>
          <main role="main" className="login-page">
            <Switch>
                <Route exact path="/reset-password/" component={ResetPassword} />
            </Switch>
          </main>
        </Provider>
      )
 
    } else if (matchPath(this.props.location.pathname, { path: '/forgotten-password/:token' })) {
      return (
        <Provider store={store}>
          <main role="main" className="login-page">
            <Switch>
                <Route exact path="/forgotten-password/:token" component={UpdatePassword} /> 
 
            </Switch>
          </main>
        </Provider>
      )
 
    } else  {
      return (
        <Provider store={store}>
          <Sidebar viewAs={viewAs} changeViewState={this.changeViewState} />
          <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            
            <Header title={title} />


            
            <Switch>
              <PrivateRoute viewAs={viewAs} exact path="/" component={Home}/>
              <PrivateRoute viewAs={viewAs} exact path="/potentials" component={Proposals}/>
              <PrivateRoute viewAs={viewAs} exact path="/companies" component={Companies}/>
              <PrivateRoute viewAs={viewAs} exact path="/add-company" component={AddCompany}/>
              <PrivateRoute viewAs={viewAs} exact path="/edit-company/:clientId" component={AddCompany}/>
              <PrivateRoute viewAs={viewAs} exact path="/company/:clientId" component={ViewClient}/>
              <PrivateRoute viewAs={viewAs} exact path="/add-potential" component={AddProposal}/>
              <PrivateRoute viewAs={viewAs} exact path="/edit-potential/:proposalId" component={AddProposal}/>
              <PrivateRoute viewAs={viewAs} exact path="/potential/:proposalId" component={ViewProposal}/>
              <PrivateRoute viewAs={viewAs} exact path="/users" component={Users}/>
              <PrivateRoute viewAs={viewAs} exact path="/edit-user/:userId" component={EditUser}/>
              <PrivateRoute viewAs={viewAs} exact path="/add-user" component={AddUser}/>
              <PrivateRoute viewAs={viewAs} exact path="/my-account" component={MyAccount}/>
              {this.props.user.accessStatus == 3 ? (
                <>
                  <PrivateRoute viewAs={viewAs} exact path="/settings" component={SettingsSuperadmin}/>
                  <PrivateRoute viewAs={viewAs} exact path="/add-emberson-company" component={Settings}/>
                  <PrivateRoute viewAs={viewAs} exact path="/edit-emberson-company/:companyId" component={Settings}/>
                </>
              ):(
                <PrivateRoute viewAs={viewAs} exact path="/settings" component={Settings}/>
              )} 
              <Route exact path="/sign-in" component={Login} />     
              <Route component={Page404} />
            </Switch>
            
            <Footer />  
          </main>
         
  

        </Provider>
      )

    } 
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { embersonCompanies },
    authentication,
    user
  } = state

  return {
    authentication,
    user,
    embersonCompanies
  }
}

export default withRouter(connect(mapStateToProps, {
  loadUser,
  loadValidate,
  loadEmbersonCompanies,
  logout
})(Root))
