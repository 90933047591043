import { CALL_API, Schemas } from '../middleware/api'

/**
 * JANK START
 */

/**
 * JANK END
 */

/**
 * Menu
 */

export const MENUS_REQUEST = 'MENUS_REQUEST'
export const MENUS_SUCCESS = 'MENUS_SUCCESS'
export const MENUS_FAILURE = 'MENUS_FAILURE'

const fetchMenus = () => ({
  [CALL_API]: {
    types: [ MENUS_REQUEST, MENUS_SUCCESS, MENUS_FAILURE ],
    endpoint: `wprr/v1/menus`,
    schema: Schemas.MENU_ARRAY
  }
})

export const loadMenus = () => (dispatch, getState) => {
  const menus = getState().entities.menus;
  if (Object.keys(menus).length > 0) {
    return null
  }

  return dispatch(fetchMenus())
}

/**
 * Options
 */

export const OPTIONS_REQUEST = 'OPTIONS_REQUEST'
export const OPTIONS_SUCCESS = 'OPTIONS_SUCCESS'
export const OPTIONS_FAILURE = 'OPTIONS_FAILURE'

const fetchOptions = () => ({
  [CALL_API]: {
    types: [ OPTIONS_REQUEST, OPTIONS_SUCCESS, OPTIONS_FAILURE ],
    endpoint: `wprr/v1/options`
  }
})

export const loadOptions = () => (dispatch, getState) => {
  return dispatch(fetchOptions())
}

/**
 * Root
 */

export const ROOT_REQUEST = 'ROOT_REQUEST'
export const ROOT_SUCCESS = 'ROOT_SUCCESS'
export const ROOT_FAILURE = 'ROOT_FAILURE'

const fetchRoot = () => ({
  [CALL_API]: {
    types: [ ROOT_REQUEST, ROOT_SUCCESS, ROOT_FAILURE ],
    endpoint: ``,
    schema: false
  }
})

export const loadRoot = () => (dispatch, getState) => {
  return dispatch(fetchRoot())
}

/**
 * Settings
 */

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST'
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS'
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE'

const fetchSettings = () => ({
  [CALL_API]: {
    types: [ SETTINGS_REQUEST, SETTINGS_SUCCESS, SETTINGS_FAILURE ],
    endpoint: `wp/v2/settings`
  }
})

export const loadSettings = () => (dispatch, getState) => {
  return dispatch(fetchSettings())
}

/**
 * Error Message
 */

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'

// Resets the currently visible error message.
export const resetErrorMessage = () => ({
    type: RESET_ERROR_MESSAGE
})
