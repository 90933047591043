import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import $ from 'jquery'; 
import { NavLink } from 'react-router-dom'

import { logout } from '../actions/authentication-actions'


class Menus extends Component {

  constructor(props) {
    super(props); 
    this.handleLogout = this.handleLogout.bind(this);
  }
 
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired, 
    logout: PropTypes.func.isRequired,
  }

  componentWillMount() { 
  }

  toggleNav(e) {
    e.preventDefault();
    document.body.classList.toggle('g-sidenav-pinned');
  }

  componentDidMount() { 
    var page = this;
    $(function() {
      $("#menu-toggle").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
      });
      $(".dropdown-menu > li a").on("click", function(e) {
        var link = $(this).attr('href');
        page.props.history.push(link)
      });
      $(".dropdown-menu .logout").on("click", function(e) {
        e.stopPropagation(); 
        page.handleLogout();
      });
    });

    

  }

  handleLogout() {
    this.props.history.push('/');
    this.props.logout(); 
  }
 

  render() {
     
  
    const user = this.props.user;
    const userId = user.iD; 
    const authentication = this.props.authentication;
    const accessStatus = user.accessStatus;
    var profilePictureStyle = {};
 
    return (
      <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
        <div className="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
              <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="javascript:;">Pages</a></li>
              <li className="breadcrumb-item text-sm text-dark active" aria-current="page">{this.props.title}</li>
            </ol>
            <h6 className="font-weight-bolder mb-0">{this.props.title}</h6>
          </nav>
          <Link className="btn btn-outline-primary btn-sm mb-0 me-3" to="/add-potential">Add Potential +</Link>
          
          <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
            
            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
               
              {/*
               
                
              <div className="input-group">
                <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                <input type="text" className="form-control" placeholder="Type here..." />
              </div>
              */}
            </div>
            
            <ul className="navbar-nav  justify-content-end">
              {/*
              <li className="nav-item d-flex align-items-center">
                <a className="btn btn-outline-primary btn-sm mb-0 me-3" target="_blank" href="https://www.creative-tim.com/builder/soft-ui?ref=navbar-dashboard">Online Builder</a>
              </li>
               
              <li className="nav-item d-flex align-items-center">
                <a href="#" className="nav-link text-body font-weight-bold px-0">
                  <i className="fa fa-user me-sm-1"></i>
                  <span className="d-sm-inline d-none">Sign In</span>
                </a>
              </li>
              */}
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a href="#" onClick={(e) => this.toggleNav(e)} className="nav-link text-body p-0" id="iconNavbarSidenav">
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </a>
              </li>
              {/*
              <li className="nav-item px-3 d-flex align-items-center">
                <a href="javascript:;" className="nav-link text-body p-0">
                  <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                </a>
              </li>
              <li className="nav-item dropdown pe-2 d-flex align-items-center">
                <a href="javascript:;" className="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-bell cursor-pointer"></i>
                </a>
                <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                  <li className="mb-2">
                    <a className="dropdown-item border-radius-md" href="javascript:;">
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img src="../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 "/>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">New message</span> from Laur
                          </h6>
                          <p className="text-xs text-secondary mb-0 ">
                            <i className="fa fa-clock me-1"></i>
                            13 minutes ago
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a className="dropdown-item border-radius-md" href="javascript:;">
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img src="../assets/img/small-logos/logo-spotify.svg" className="avatar avatar-sm bg-gradient-dark  me-3 "/>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">New album</span> by Travis Scott
                          </h6>
                          <p className="text-xs text-secondary mb-0 ">
                            <i className="fa fa-clock me-1"></i>
                            1 day
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item border-radius-md" href="javascript:;">
                      <div className="d-flex py-1">
                        <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                         
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            Payment successfully completed
                          </h6>
                          <p className="text-xs text-secondary mb-0 ">
                            <i className="fa fa-clock me-1"></i>
                            2 days
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
              */}
            </ul>
          </div>
        </div>
      </nav> 
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    entities: { embersonCompanies },
    authentication, 
    user, 
  } = state
 

  return {
    authentication, 
    user, 
    embersonCompanies
  }
}

export default withRouter(connect(mapStateToProps, { 
  logout, 
})(Menus))
