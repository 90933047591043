import { CALL_API, Schemas } from '../middleware/api'
 

export const EMBERSON_COMPANIES_REQUEST = 'EMBERSON_COMPANIES_REQUEST'
export const EMBERSON_COMPANIES_SUCCESS = 'EMBERSON_COMPANIES_SUCCESS'
export const EMBERSON_COMPANIES_FAILURE = 'EMBERSON_COMPANIES_FAILURE'
 
const fetchEmbersonCompanies = () => ({
  [CALL_API]: {
    types: [ EMBERSON_COMPANIES_REQUEST, EMBERSON_COMPANIES_SUCCESS, EMBERSON_COMPANIES_FAILURE ],
    endpoint: `secure/get-emberson-companies`,
    schema: Schemas.EMBERSON_COMPANIES_ARRAY
  }
})

export const loadEmbersonCompanies = () => (dispatch, getState) => {
  const embersonCompanies = getState().entities.embersonCompanies;
  if (Object.keys(embersonCompanies).length > 0) {
    Object.keys(embersonCompanies).map(function(key) {

      delete embersonCompanies[key];

    })  
  }
 
  return dispatch(fetchEmbersonCompanies())
}



export const UPLOAD_PROFILE_IMAGE_REQUEST = 'UPLOAD_PROFILE_IMAGE_REQUEST'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE'

const fetchUpdateCompanyProfilePicture = (body) => ({
  [CALL_API]: {
    types: [ UPLOAD_PROFILE_IMAGE_REQUEST, UPLOAD_PROFILE_IMAGE_SUCCESS, UPLOAD_PROFILE_IMAGE_FAILURE ],
    endpoint: 'secure/emberson-companies/upload-profile-picture',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateCompanyProfilePicture  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateCompanyProfilePicture(body))
};



export const UPDATE_EMBERSON_COMPANY_REQUEST = 'UPDATE_EMBERSON_COMPANY_REQUEST'
export const UPDATE_EMBERSON_COMPANY_SUCCESS = 'UPDATE_EMBERSON_COMPANY_SUCCESS'
export const UPDATE_EMBERSON_COMPANY_FAILURE = 'UPDATE_EMBERSON_COMPANY_FAILURE'

const fetchUpdateEmbersonCompany = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_EMBERSON_COMPANY_REQUEST, UPDATE_EMBERSON_COMPANY_SUCCESS, UPDATE_EMBERSON_COMPANY_FAILURE ],
    endpoint: 'secure/emberson-companies/update-emberson-company',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateEmbersonCompany  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateEmbersonCompany(body))
};

 

 