import React, {Component} from 'react'; 
import PropTypes from 'prop-types'
import { connect, Provider } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'

class Footer extends Component {

  constructor(props) {
    super(props); 
  }

  static propTypes = {
    authentication: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired, 
  }

  componentWillMount() {  
  }

  render() {
     

    return (
      <footer className="mt-4 pt-4">
         
      </footer>
    )
  }
}


const mapStateToProps = (state, ownProps) => {

  const {
    entities: { },
    authentication, 
    user
  } = state
 

  return {
    authentication, 
    user,
  }
}

export default withRouter(connect(mapStateToProps, { 
})(Footer))