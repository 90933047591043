import { CALL_API, Schemas } from '../middleware/api'

export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILURE = 'USERS_FAILURE'

const fetchUsers = () => ({
  [CALL_API]: {
    types: [ USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE ],
    endpoint: `listallusersapiroute`,
    schema: Schemas.USER_ARRAY
  }
})

export const loadUsers = () => (dispatch, getState) => {
  const users = getState().entities.users;
  if (Object.keys(users).length > 0) {
    return null
  }

  return dispatch(fetchUsers())
}

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

const fetchUser = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ USER_REQUEST, USER_SUCCESS, USER_FAILURE ],
    endpoint: `secure/users/${userId}`,
    schema: userId === 'me' ? null : Schemas.USER
  }
})

export const loadUser = (userId) => (dispatch, getState) => {
  const user = getState().entities.users[userId];
  if (user) {
    return null
  }

  return dispatch(fetchUser(userId))
}


export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

const fetchUpdateUserCategoryPreference = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE ],
    endpoint: 'secure/users/update-category',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserCategoryPreference = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserCategoryPreference(body))
};


export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE'

const fetchUpdateUserProfile = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_SUCCESS,UPDATE_USER_PROFILE_FAILURE ],
    endpoint: 'users/update-user-profile',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserProfile  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserProfile(body))
};


const fetchSecureUpdateUserProfile = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_SUCCESS,UPDATE_USER_PROFILE_FAILURE ],
    endpoint: 'secure/users/update-user-profile',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateSecureUserProfile  = (body) => (dispatch, getState) => {
  return dispatch(fetchSecureUpdateUserProfile(body))
};

 



export const UPLOAD_PROFILE_IMAGE_REQUEST = 'UPLOAD_PROFILE_IMAGE_REQUEST'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE'

const fetchUpdateUserProfilePicture = (body) => ({
  [CALL_API]: {
    types: [ UPLOAD_PROFILE_IMAGE_REQUEST, UPLOAD_PROFILE_IMAGE_SUCCESS, UPLOAD_PROFILE_IMAGE_FAILURE ],
    endpoint: 'secure/users/upload-profile-picture',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateUserProfilePicture  = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateUserProfilePicture(body))
};

   
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE'

const fetchUpdateCustomer = (body) => ({
  body,
  [CALL_API]: {
    types: [UPDATE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILURE],
    endpoint: `/users/update-user-profile`,
    schema: null, 
    method: 'POST',
    body: body
  }
})

export const updateCustomerProfile = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateCustomer(body))
}





export const COMPANY_USERS_REQUEST = 'COMPANY_USERS_REQUEST'
export const COMPANY_USERS_SUCCESS = 'COMPANY_USERS_SUCCESS'
export const COMPANY_USERS_FAILURE = 'COMPANY_USERS_FAILURE'
 
const fetchCompanyUsers = () => ({
  [CALL_API]: {
    types: [ COMPANY_USERS_REQUEST, COMPANY_USERS_SUCCESS, COMPANY_USERS_FAILURE ],
    endpoint: `secure/users/company-users`,
    schema: Schemas.COMPANY_USERS_ARRAY
  }
})

export const loadCompanyUsers = () => (dispatch, getState) => {
  const companyUsers = getState().entities.companyUsers;
  if (Object.keys(companyUsers).length > 0) {
    Object.keys(companyUsers).map(function(key) {

      delete companyUsers[key];

    })  
  }
  return dispatch(fetchCompanyUsers())
}




export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE'

const fetchRemoveUser = (body) => ({
  body,
  [CALL_API]: {
    types: [REMOVE_USER_REQUEST, REMOVE_USER_SUCCESS, REMOVE_USER_FAILURE],
    endpoint: `secure/users/remove-user`,
    schema: null, 
    method: 'POST',
    body: body
  }
})

export const updateRemoveUser = (body) => (dispatch, getState) => {
  return dispatch(fetchRemoveUser(body))
} 