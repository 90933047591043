import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import persistState from 'redux-localstorage'

import api from '../middleware/api';
import rootReducer from '../reducers';

// Production store
let configureStore = preloadedState => createStore(
  rootReducer,
  preloadedState,
  compose(
    applyMiddleware(thunk, api),
    //persistState(),
  )
);

// use Redux devtools if available in development
if (process.env.NODE_ENV === 'development') {
  // declare any enhancers here
  const enhancers = [];

  const devToolsExtension = window.devToolsExtension;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }

  configureStore = preloadedState => {
    const store = createStore(
      rootReducer,
      preloadedState,
      compose(
        applyMiddleware(thunk, api, createLogger()),
        ...enhancers,
      ),
    );

    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('../reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }

    return store;
  };
}

export default configureStore
