import "es6-string-polyfills";
import 'babel-polyfill'; 
import 'react-app-polyfill/ie11';
import 'es6-shim';
import 'isomorphic-fetch';
import 'html5shiv';
import 'core-js';

import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './containers/Root'
import configureStore from './store/configureStore'
import * as serviceWorker from './serviceWorker';
import config from "./config";

import 'bootstrap'
import './stylesheets/main.scss' 


const store = configureStore()
 

render(
  <Router>
    <Root store={store} />
  </Router>,
  document.getElementById('root')
)

 
serviceWorker.unregister();
