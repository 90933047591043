import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import $ from 'jquery'; 
import { NavLink } from 'react-router-dom'
import { loadEmbersonCompanies } from '../actions/embersonCompanies-actions'
import { logout } from '../actions/authentication-actions'


class Sidebar extends Component {

  constructor(props) {
    super(props); 
    this.state = {
      viewAs: this.props.user.companyId,
    }; 
    this.handleLogout = this.handleLogout.bind(this);
  }
 
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired, 
    logout: PropTypes.func.isRequired,
    loadEmbersonCompanies: PropTypes.func.isRequired,
  }

  componentWillMount() { 
    this.props.loadEmbersonCompanies()
  }

  componentDidMount() { 
   

  }

  componentDidUpdate(prevProps) {
    if (prevProps.viewAs !== this.props.viewAs) {
      this.setState({viewAs: this.props.viewAs})
    }
  }

  handleLogout() {
    this.props.history.push('/');
    this.props.logout(); 
    this.setState({viewAs: 0})
    document.body.className = 'g-sidenav-show bg-gray-100';
  }

  getEmbersonCompanyList() {
    
    const embersonCompanies = this.props.embersonCompanies;
    let array = [];
    for (var key in embersonCompanies) { 
      array.push(<option value={embersonCompanies[key].companyId}>{embersonCompanies[key].companyName}</option>)
    }
    return array
  }
 

  render() {
     
  
    const user = this.props.user;
    const userId = user.iD; 
    const authentication = this.props.authentication;
    let companyId = user.companyId;

    if (this.props.user.accessStatus == 3 && this.state.viewAs) {
      companyId = this.state.viewAs;
    }


    const embersonCompanies = this.props.embersonCompanies[companyId];
    let companyLogo
    if (embersonCompanies && Object.keys(embersonCompanies).length > 0) {
      companyLogo = embersonCompanies.companyLogo;
    }

    if (this.props.user.accessStatus == 3 && this.state.viewAs == 0) {
      companyLogo = '/public/uploads/egrouplogo.png';
    }


    
    var profilePictureStyle = {};
 
    return (
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white" id="sidenav-main">
        <div className="sidenav-header">
          <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
          <Link className="navbar-brand m-0" to="/">
            <img src={'https://embersonapi.everlong.digital'+companyLogo} className="navbar-brand-img h-100" alt="main_logo" />
          </Link>
        </div>
        <hr className="horizontal dark mt-0" />

        <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
          <ul className="navbar-nav">

            {this.props.user.accessStatus == 3 && (
              <li className="nav-item">
                <div className="nav-link d-block text-center active">
                  <label>Switch Company</label>
                  <select type="text" className="form-control w-100" value={this.props.viewAs} onChange={e => this.props.changeViewState(e.target.value)} required>
                    <option value="0">All Companies</option>
                    {this.getEmbersonCompanyList()}
                  </select>
                  
                </div>

              </li>

            )}
            
            <li className="nav-item">
              <Link className="nav-link  active" to="/">
                <span className="nav-link-text ms-1">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link  active" to="/potentials">
                <span className="nav-link-text ms-1">Potentials</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link  active" to="/companies">
                <span className="nav-link-text ms-1">Companies</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link  active" to="/users">
                <span className="nav-link-text ms-1">Win Team</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link  active" to="/my-account">
                <span className="nav-link-text ms-1">My Account</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link  active" to="/settings">
                <span className="nav-link-text ms-1">Settings</span>
              </Link>
            </li>
            
 
            
            <li className="nav-item">
              <a className="nav-link  active" onClick={() => this.handleLogout()}>
                <span className="nav-link-text ms-1">Sign Out</span>
              </a>
            </li>
    
          </ul>
        </div> 
      </aside>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    entities: { embersonCompanies },
    authentication, 
    user, 
  } = state
 

  return {
    authentication, 
    user, 
    embersonCompanies
  }
}

export default withRouter(connect(mapStateToProps, { 
  logout, 
  loadEmbersonCompanies
})(Sidebar))
